import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`API Client Libraries`}</h1>
    <p>{`To make integration easier we provide API client libraries in Python, JavaScript and Swift.`}</p>
    <h3>{`Python`}</h3>
    <p>{`Available on GitHub at `}<a parentName="p" {...{
        "href": "https://github.com/BiteAI/biteai-python"
      }}>{`https://github.com/BiteAI/biteai-python`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` biteai`}</code></pre></div>
    <h3>{`JavaScript`}</h3>
    <p>{`Available on GitHub at `}<a parentName="p" {...{
        "href": "https://github.com/BiteAI/biteai-js"
      }}>{`https://github.com/BiteAI/biteai-js`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` biteai`}</code></pre></div>
    <h3>{`Swift`}</h3>
    <p>{`The Swift client is available at `}<a parentName="p" {...{
        "href": "https://github.com/BiteAI/biteai-swift"
      }}>{`https://github.com/BiteAI/biteai-swift`}</a></p>
    <p>{`Add the dependencies  to your project`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pod `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Apollo"`}</span>{`, `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=0.8.0"`}</span>{`
pod `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Alamofire"`}</span>{`, `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"~>4.5"`}</span></code></pre></div>
    <p>{`Add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BiteAIClient`}</code>{` pod to your project`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pod `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"BiteAIClient"`}</span>{`, :git `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'git@github.com:BiteAI/biteai-swift.git'`}</span>{`, :tag `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'v0.0.4'`}</span></code></pre></div>
    <p>{`Add BiteAI configuration to Plist`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`BiteAI`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dict`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`AppKey`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`string`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`[INSERT KEY]`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`string`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`BaseURL`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`key`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`string`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`https://api-beta.bite.ai/`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`string`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dict`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      